/* You can add global styles to this file, and also import other style files */
// PrimeNg imports
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "@ng-select/ng-select/themes/default.theme.css";
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "./assets/scss/owl-custom";

.input-error-text {
  border: 1px solid red !important;
}

.select-error-text {
  border: 1px solid red !important;
  border-radius: 0.5rem !important;
}

.input-error-checkbox {
  outline: auto;
  color: red !important;
}

.p-error {
  color: #e24c4c;
  margin-top: 0.25rem !important;
}

.border-end-none {
  border-right: none;
}

.fit-content {
  width: fit-content;
}

.bg-eye-white {
  background-color: #fff !important;
}

.mb-unset-input {
  margin-bottom: unset !important;
}

.theme-card {
  padding: 30px;
  border: none;
  line-height: 1;
  background-color: #fafafa;
}

.cursor-hover {
  cursor: pointer;
}

.capitalize-first-letter:first-letter {
  text-transform: capitalize !important;
}


.ng-select.ng-select-single .ng-select-container {
  height: 42px !important;
  padding-left: 15px;
  border-radius: 0.5rem;
}

#ng-select-search-large,
#ng-select-search {
  .ng-select-container {
    border-radius: 3rem;

    .ng-arrow-wrapper {
      display: none !important;
    }
  }

  ng-select:has(ng-dropdown-panel) {
    .ng-select-container {
      border-radius: 1.5rem 1.5rem 0 0 !important;
    }

    ng-dropdown-panel.ng-dropdown-panel {
      border: none;
    }
  }
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  height: 42px !important;
}

.search-item .ng-select.ng-select-single .ng-select-container {
  height: 50px !important;
  padding-left: 15px;
  background-color: #f5f5f5;
  border: none;
  min-width: 22rem; /* Default min-width */
}

/* Per schermi grandi (oltre 1200px) */
@media (min-width: 1200px) {
  .search-item .ng-select.ng-select-single .ng-select-container {
    min-width: 25rem;
  }
}

/* Per schermi medi (tablet, tra 768px e 1199px) */
@media (max-width: 1199px) and (min-width: 768px) {
  .search-item .ng-select.ng-select-single .ng-select-container {
    min-width: 18rem;
  }
}

/* Per schermi piccoli (smartphone, fino a 767px) */
@media (max-width: 767px) {
  .search-item .ng-select.ng-select-single .ng-select-container {
    min-width: 14rem;
  }
}


.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: #FAFAFA;
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}

.address-book-section {
  .select-box {
    .address-box {
      padding: 12px;
      background-color: white;
      transition: all 0.5s ease;
      height: 14rem;
      border-radius: 10px;
      box-shadow: 0 2px 12px 0 #ababab47;

      .top {
        h6 {
          text-transform: capitalize;
          color: #222222;
          font-weight: 600;
          font-size: 14px;

          span {
            float: right;
            color: #D9230FFF;
            border: thin solid;
            padding: 6px 15px;
            font-size: 70%;
            border-radius: 3px;
          }
        }
      }

      .middle {
        margin-top: 15px;

        .address {
          p {
            font-size: 15px;
            margin-bottom: 5px;
            color: rgba(0, 0, 0, 0.7);
            line-height: 1.2;
          }
        }

        .number {
          margin-top: 15px;

          p {
            color: rgba(0, 0, 0, 0.7);
            text-transform: capitalize;
          }
        }
      }

      .card-number {
        margin-top: 15px;

        h6,
        h5 {
          margin-bottom: 0;
        }
      }

      .name-validity {
        margin-top: 10px;
        display: flex;
        align-items: center;

        h6 {
          text-transform: capitalize;
        }

        h5,
        h6 {
          margin-bottom: 0;
        }

        .right {
          margin-left: auto;
        }
      }

      .bank-logo {
        display: flex;
        align-items: center;

        img {
          height: 22px;
        }

        .network-logo {
          margin-left: auto;
          width: 50px;
          height: auto;
        }
      }

      .bottom {
        border-top: 1px solid #dddddd;
        display: flex;
        text-align: center;
        padding-top: 16px;
        padding-bottom: 16px;
        justify-content: space-between;

        .bottom_btn {
          font-weight: 600;
          padding: 8px 8px;
          border-radius: 5px;
          color: #D9230FFF;
          border: thin solid;
        }
      }
    }
  }

  .select-box.active {
    .address-box {
      transition: all 0.5s ease;
    }
  }

}

.small-button {
  padding: 7px 16px;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  margin-left: 1px;
  line-height: 1;
  margin-bottom: 0 !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container, .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  padding-left: 0;
  align-items: center;
  height: 100%;
  top: 0;
  position: relative;
  display: flex;
}

ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  left: 0 !important;
  padding-left: 0 !important;
  top: 0 !important;
  height: 100%;
  display: flex;
  align-items: center;
}

// Override class primeNG

.p-datatable .p-datatable-thead > tr > th {
  background-color: white !important;
  border-bottom: white !important;
}

.p-datatable .p-datatable-tbody > tr {
  border: none;
  border-bottom: 1px solid #dddddd;
}

.p-datatable .p-datatable-tbody > tr > td {
  background-color: #f9f9f9 !important;
  border: none !important;
  border-bottom: 1px solid #dddddd !important;
}

.cart-table-custom {
  .p-datatable .p-datatable-tbody > tr > td {
    background-color: unset !important;
  }
}

div.p-paginator {
  margin-top: 1rem !important;
  background-color: #f9f9f9 !important;
  border: none !important;
  @media only screen and (min-width: 200px) and (max-width: 767px) {
    font-size: small;
    justify-content: center !important;
  }
}

.search-paginator {
  div.p-paginator.p-component {
    border: none !important;
    background-color: white !important;
    margin: 0 !important;
  }
}

.paginator-custom-style {
  div.p-paginator {
    background-color: #ffffff !important;
  }

  .p-paginator .p-paginator-rpp-options.p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled {
    background-color: #ffffff !important;
  }
}

/*div.p-paginator.p-component {
  border: solid 1px #6B727F !important;
  justify-content: flex-end;
}*/

.p-paginator .p-paginator-rpp-options.p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled {
  border: 1px solid #ced4da !important;
  background-color: #f9f9f9 !important;
}

.stepper.stepper-links {
  .stepper-nav {
    display: flex;
    margin: 0 auto;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 5px;

    .stepper-item {
      position: relative;
      flex-shrink: 0;
      margin: 1rem 1.5rem;

      &:after {
        content: " ";
        position: absolute;
        top: 4rem;
        left: 0;
        height: 2px;
        width: 100%;
        background-color: transparent;
        transition: color .2s ease;
      }

      .stepper-title {
        color: #00000c;
        font-weight: 600;
        font-size: 1.40rem;
      }

      // Current
      &.current {
        transition: color .2s ease;

        .stepper-title {
          color: #D9230F;
        }

        &:after {
          background-color: #D9230F;
        }
      }

      &.current.mark-completed:last-child,
      &.completed {
        .stepper-title {
          color: #00000c;
        }

      }
    }
  }
}

.fs-15-px {
  font-size: 15px
}

.font-bold {
  font-weight: bold;
}

.input-custom-style {
  border-color: #dddddd !important;
  font-size: 14px !important;
  padding: 17px 25px !important;
  margin-bottom: 30px !important;
  height: inherit !important;
  border-radius: 0.5rem !important;
}

.main-menu.border-section.border-top-0 {
  height: 80px;
}

.border-radius-10 {
  border-radius: 10px;
}

.text-transform-none {
  text-transform: none !important;
}

.text-align-last-center {
  text-align-last: center;
}

.default-size-tag {
  height: 17px;
  padding: 1px;
  margin-bottom: 4px;
  object-fit: contain;
}

.text-red-theme {
  color: #D9230F !important;
}

.product-slider-nav-sx {
  background-image: url("./assets/images/icon/general/icn_freccia_sx_red.svg") !important;
}

.product-slider-nav-dx {
  background-image: url("./assets/images/icon/general/icn_freccia_dx_red.svg") !important;
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}

.absolute-eye {
  position: absolute;
  right: 1px;
  height: 2.6rem;
  background: transparent;
  border: transparent;
  margin-top: 0.1rem;
  z-index: 6 !important;
}

.border-radius-password-right {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.title-form-responsive {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 0.50rem;
}

.button-form-responsive {
  display: flex;
  align-items: center;
  justify-content: end;
  @media only screen and (min-width: 200px) and (max-width: 575px) {
    margin-top: 0.50rem;
    margin-bottom: 0.50rem;
    justify-content: start;
  }
}

.responsive-dropdown-profile {
  @media only screen and (min-width: 200px) and (max-width: 575px) {
    transform: translate3d(-87px, -34px, 0px) !important
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.eye-transparent {
  background-color: transparent !important;
}

.custom-list {
  li {
    display: list-item;
  }
}

.min-w-120px {
  min-width: 120px;
}

.h-42px {
  height: 42px !important;
}

.default-dim-modals-button {
  width: 150px;
  height: 40px;
  font-size: small;
  padding: 0;
  margin: 0.50rem !important;
  line-height: normal;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: #e9ecef !important;
}

.ng-select .ng-select-container {
  border: 1px solid #dddddd;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: #e9ecef !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(233, 236, 239, 0.1) !important;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: rgba(88, 91, 93, 0.91);
}

.form-control:focus {
  border-color: #e9ecef !important;
}

app-ng-select.search-item {
  z-index: 1;

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background-color: #f5f5f5 !important;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color: #d5d8da !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #e9ecef !important;
}

.p-button.p-button-text:not(:disabled):active {
  background-color: #e9ecef !important;
}

.p-button:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #e9ecef, 0 1px 2px 0 rgb(0, 0, 0) !important;
}

.p-button.p-button-text:not(:disabled):hover {
  background-color: #eff2f5 !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #D9230F !important;
  border-color: #D9230F !important;
  color: #fff !important;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: #eff2f5 !important;
}

.p-dropdown:not(.p-disabled).p-focus {
  outline: none !important;
  border: none !important;
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #e9ecef, 0 1px 2px 0 rgb(0, 0, 0) !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
  background: #eff2f5 !important;
  color: #4b5563 !important;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.1rem rgba(233, 236, 239, 1) !important;
  border-color: #eff2f5 !important;
}

.p-inputtext:enabled:hover {
  border-color: #eff2f5 !important;
}

input[type=checkbox] {
  accent-color: #D9230F !important;
  padding: 0;
  height: 11px !important;
}

p-paginator {
  p-dropdown {
    p-overlay {
      ul.p-dropdown-items {
        li {
          width: 100% !important;
        }
      }
    }
  }
}

.line-height-custom {
  line-height: normal;
  padding: 10px 20px
}

.input-with-image {
  position: relative;
}

.input-image {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
}

.input-group-append {
  margin-left: 2px;
  display: flex;
}

.search-bar-btn-search {
  background-color: #D9230F;
  color: white;
  border-radius: 9999px !important;
  right: 6rem;
  z-index: 1002 !important;
  top: 0;

  mat-icon {
    padding-bottom: 1.7rem;
    padding-right: 1.6rem;
  }
}

.search-bar-btn-search:hover {
  background-color: #D9230F !important;
  color: white !important;
}

.search-bar-btn-mic {
  right: 5.5rem;
  z-index: 1002 !important;
  top: 0;

  img {
    width: 1.6rem;
  }
}

.search-bar-btn-mic.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  border: none;
}

@media (min-width: 992px) {
  .search-bar-full-width {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .search-bar-standard {
    display: none !important;
  }
}

.p-galleria .p-galleria-item-nav {
  color: unset;
  width: 2rem;
  height: 2rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
  margin: 0 0.5rem;
  background-color: #fff;
  box-shadow: rgba(186, 181, 181, 0.2901960784) 0 0 20px 3px;
  opacity: 1;
}

.p-galleria .p-galleria-item-nav .p-icon-wrapper .p-icon {
  width: 1rem;
  height: 1rem;
}

.text-align-center {
  text-align: center;
}

.fw-600 {
  font-weight: 600;
}

.fw-800 {
  font-weight: 800;
}

.single {
  #drop-area-border-1, #drop-area-border-2, #drop-area-border-3, #drop-area-border-4, #drop-area-border-5 {
    border-radius: 8px;
    background-color: #ffffff;
  }

  #drop-area-1, #drop-area-2, #drop-area-3, #drop-area-4, #drop-area-5 {
    border: 2px dashed #D9230F;
    border-radius: 8px;
    padding: 4px;
    text-align: center;
    display: flex;
    justify-content: space-evenly;
  }

  #file-input-1, #file-input-2, #file-input-3, #file-input-4, #file-input-5 {
    display: none;
  }

  #file-label-header-1, #file-label-header-2, #file-label-header-3, #file-label-header-4, #file-label-header-5 {
    font-size: 26px;
    color: black;
  }

  #file-label-1, #file-label-2, #file-label-3, #file-label-4, #file-label-5 {
    font-size: 13px;
  }
}

//---------------------FONT MANAGEMENT -----------------//
h1 {
  font-size: clamp(1.25rem, 2.5vw + 1rem, 2.5rem);
  text-transform: lowercase;
}

h2 {
  font-size: clamp(1.125rem, 2vw + 0.875rem, 2rem);
  text-transform: lowercase;
  font-weight: 700;
}

h3 {
  font-size: clamp(1rem, 1.75vw + 0.75rem, 1.75rem);
  text-transform: lowercase;
}

h4 {
  font-size: clamp(0.9rem, 1.5vw + 0.625rem, 1.5rem);
  text-transform: lowercase;
}

h5 {
  font-size: clamp(0.70rem, 1.25vw + 0.25rem, 1rem);
}

h6 {
  font-size: clamp(0.9rem, 1vw + 0.375rem, 1.2rem);
}

h1::first-letter {
  text-transform: capitalize;
}


h2::first-letter {
  text-transform: capitalize;
}

h3::first-letter {
  text-transform: capitalize;
}

h4::first-letter {
  text-transform: capitalize;
}

h5::first-letter {
  text-transform: capitalize;
}

h6::first-letter {
  text-transform: capitalize;
}

p {
  font-size: clamp(0.5rem, 1.5vw + 0.75rem, 01rem);
  line-height: 1.6;
  margin-bottom: 1em;
}

p::first-letter {
  text-transform: capitalize;
}

.profile-page h3, .profile-page .h3 {
  margin-bottom: 20px;
  text-transform: none !important;
  color: #222222;
  margin-top: -4px;
  font-size: 25px;
}

.static-page h3, .static-page .h3 {
  margin-bottom: 20px;
  text-transform: none !important;
  color: #222222;
  margin-top: -4px;
  font-size: 25px;
}


.btn {
  white-space: normal;
  @media only screen and (min-width: 200px) and (max-width: 575px) {
    line-height: 0.9;
  }
}

.p-0-responsive {
  padding: 0;
  @media only screen and (min-width: 200px) and (max-width: 575px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.text-align-right {
  text-align: right;
}

.white-space-no-wrap {
  white-space: nowrap;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1051;
}

.newsletterm .modal-content {
  width: 700px !important;
  height: 285px !important;

  @media only screen and (min-width: 200px) and (max-width: 575px) {
    width: 350px !important
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    width: 450px !important
  }
}

.newsletterm .modal-dialog {
  justify-content: center;
}

input {
  height: 42px !important;
}

.p-paginator .p-paginator-current {
  font-size: 0.85rem !important;
}

body,
app-shop,
app-profile,
app-showcase,
app-pages {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between
}

router-outlet {
  position: absolute;
}

header .main-navbar .nav-menu > li > a .lable-nav {
  position: absolute;
  background-color: var(--theme-deafult);
  padding: 2px 7px;
  color: #ffffff;
  top: 0;
  text-transform: uppercase;
  font-size: 9px;
  left: 0;
  line-height: 1.3;
  margin-left: 40%;
}

.fs-16 {
  font-size: 16px;
}

.mw-profile-section {
  min-height: 35.30rem;
}

.radio-button-disabled > .p-radiobutton {
  cursor: default !important;
}

.text-disabled-radio-button {
  color: #ced4da !important;
}

.btn-solid-dark {
  .btn-solid {
    padding: 13px 20px;
    color: #ffffff !important;
    letter-spacing: 0.05em;
    border-radius: 10px;
    border: 2px solid #212529 !important;
    background-image: -webkit-linear-gradient(30deg, #212529 50%, transparent 50%) !important;
    background-image: linear-gradient(30deg, #212529 50%, transparent 50%) !important;
    background-size: 850px;
    background-repeat: no-repeat;
    background-position: 0;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
  }
}

.reset-inner-html {
  h1, h2, h3, h4, h5, h6, p, div {
    margin: 0;
    padding: 0;
  }

  ul, ol {
    padding: 0;
    margin-left: 1rem;
    color: inherit !important;
  }
}

.display-list-item {
  display: list-item;
}

.mat-mdc-tooltip-surface {
  box-shadow: 0 0 5px 0 #888888 !important;
  color: black !important;
  background-color: #ffffff !important;
}

#cartTable {
  .p-datatable > .p-datatable-wrapper {
    overflow: unset;
    @media only screen and (min-width: 200px) and (max-width: 575px) {
      overflow: auto;
    }

    @media only screen and (min-width: 576px) and (max-width: 767px) {
      overflow: auto;
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
      overflow: auto;
    }
  }
}

#addressCartSelect {
  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    padding-right: 10px;
  }
}

.btn-solid-secondary {
  padding: 13px 20px;
  color: #ffffff !important;
  letter-spacing: 0.05em;
  border-radius: 10px;
  background-size: 850px;
  background-repeat: no-repeat;
  background-position: 0;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
  background-color: rgb(142, 142, 142);
  border: rgb(142, 142, 142);
}

input[type="checkbox"] {
  width: 11px;
  height: 15px;
  transform: scale(1.5);
  cursor: pointer;
}

.illustration-h {
  height: 20rem !important;
}

.custom-input-note {
  border-radius: 10px;
  font-size: 16px !important;
  padding: 10px 20px !important;
  height: 8rem !important;
  margin-bottom: 0.5rem !important;
  resize: none !important;
}